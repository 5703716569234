import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
    changeImport(res) {
        tools.msg(res, this);
    },
    reloadPage() {
        this.reload();
        setTimeout(() => {
            this.$message({
                type: 'success',
                message: '刷新成功！'
            });
        }, 800);
    },
    unbind(sku_id) {
        let loader = tools.loading(false, this);
        apis.bindGoodsOnlineWithLocal({
            sku_id: sku_id
        }).then(res => {
            if (!tools.msg(res, this)) {
                tools.loading(loader, this);
                return;
            }
            this.$message({
                type: 'success',
                message: '解绑成功！'
            });

            this.getList();
            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });

    },
    // 绑商品
    bind(sku) {
        this.createShareFlag = true;
        this.currentId = sku;
    },
    // 绑组合
    bindGroup(sku) {
        this.createShareFlag2 = true;
        this.currentId = sku;
    },

    flushOnline() {
        this.getList(true);
        setTimeout(() => {
            this.$message({
                type: 'success',
                message: '下载成功！'
            });
        }, 800);
    },
    //导出Excel
    exportExcel() {
        this.exportExcelFlag = true;
        apis.exportBinding(this.req).then(res => {
            this.exportStatus = 2;
            let blob = new Blob([res]);
            let url = URL.createObjectURL(blob);
            setTimeout(() => {
                this.exportExcelFlag = false;
                this.exportStatus = 1;
            }, 2500);
            // 下载该blob链接
            tools.downloadFile(url, document.title + tools.getDateTime() + '.xls')
        }).catch(err => {
            // console.log(err);
            this.exportStatus = 3;
        });
    },
    download_bind(sku_id) {
        let sku_list = [];
        if (sku_id) {
            sku_list.push(sku_id);
        } else {
            this.selectData.forEach(item => {
                sku_list.push(item.commerce_sku_id);
            });
        }
        if (sku_list.length <= 0) {
            this.$message({
                type: 'error',
                message: '最少选择一个sku进行绑定'
            })
            return;
        }
        let loader = tools.loading(false, this);
        // 
        apis.bindGoodsOnlineDownload({
            commerce_sku_id: JSON.stringify(sku_list)
        }).then(res => {
            if (res.code == 200) {
                this.$message({
                    type: 'success',
                    message: '绑定成功'
                })
                this.getList();
            } else {
                tools.msg(res, this);
            }

            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });
    },
    getSelect(item) {
        this.selectData = item;
    },
    shareExit() {
        this.createShareFlag = false;
    },
    shareExit2() {
        this.createShareFlag2 = false;
    },
    fleshList() {
        this.getList();
    },
    async getList(syncFlag) {
        let loader = tools.loading(false, this);
        if (this.shopList.length <= 0) {
            // 获取页面数据源
            await apis.getBindSource().then(res => {
                this.shopList = res.data.commerce_shop;
                this.classify = res.data.commerce_goods_classify;
                if (this.shopList.length > 0) {
                    // 默认选中第一个店铺
                    this.req.commerce_shop_id = this.shopList[0].id;
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }

        // let syncFlag = sessionStorage.getItem('syncFlag');
        // console.log(syncFlag);
        if (syncFlag === true) {
            // 刷新商品列表
            await apis.syncGoodsList(this.req).then(res => {
                tools.msg(res, this);
            }).catch(err => {
                tools.err(err, this);
            });
        }


        // 获取线上商品列表
        await apis.getGoodsOnline(this.req).then(res => {
            this.tableData = res.data.data;
            this.total = res.data.total;
            this.tableData.forEach(element => {
                // element.sku_name = element.commerce_sku.join(',')
            });
        }).catch(err => {
            tools.err(err, this);
        });

        tools.loading(loader, this);
    },
    onSubmit() {
        this.getList();
    },
    handleSizeChange(val) {
        this.req.limit = val;
        this.getList();
    },
    handleCurrentChange(val) {
        this.req.page = val;
        this.getList();
    },
}
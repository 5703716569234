import tools from "../../../extends/tools";
export default {
    baseUrl: tools.baseURL(),
    showImportFlag: false,
    fileUrl: localStorage.getItem('baseUrl') + '/api/goods/binding/excel',
    authorizationBearer: tools.getToken(),
    selectData: [],
    tableData: [],
    createShareFlag: false,
    createShareFlag2: false,
    shareListFlag: false,
    currentId: 0,
    total: 0,
    currentPage: 1,
    shareId: 0,
    statusList: [],
    req: {
        limit: 30,
        page: 1,
        keywords: '',
        commerce_shop_id: ''
    },
    shopId: '',
    defaultProps: {
        children: 'child',
        label: 'name'
    },
    pid: 0,
    parentName: '',
    showSrc: '',
    showPicFlag: false,
    // 数据源
    shopList: [],
    classify: []
}
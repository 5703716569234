<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card" shadow="never">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                     <el-button type="success" @click="showImportFlag=true" class="buttons">
                                导入Excel
                     </el-button>    
                     <el-button type="primary" @click="exportExcel" class="buttons">导出Excel</el-button>
                     <el-button  @click="reloadPage" class="buttons">刷新</el-button>
                     <el-button type="warning" @click="flushOnline" class="buttons">下载商品</el-button>
                     <el-button type="primary" @click="download_bind()" class="buttons">批量增绑</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="店铺">
                        <el-select v-model="req.commerce_shop_id">
                            <el-option v-for="(item,key) in shopList" 
                            :key="key"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                    <el-form-item label="商品分类">
                        <el-cascader
                        style="display:inline-block;"
                        v-model="req.commerce_goods_classify"
                        width="130"
                        :options="classify"
                        :show-all-levels="false"
                        :props="{ 
                            expandTrigger: 'hover',
                            children:'child',
                            emitPath:false,
                            value:'id',
                            label:'name'
                        }"></el-cascader>
                    </el-form-item>

                    <el-form-item>
                        <el-input
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                    </el-form-item>
                </el-form>

                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
                        @selection-change="getSelect"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width:96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="线上宝贝名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.commerce_goods }}</template>
                    </el-table-column>
                    <el-table-column label="线上商家编码"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.commerce_sku_excel }}</template>
                    </el-table-column>

                    <el-table-column label="状态"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.status }}</template>
                    </el-table-column>
                    <el-table-column label="操作"  min-width="65" width="240" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" 
                            title="解除线上商品和线下商品的绑定关系" 
                            v-if="scope.row.is_binding>0" @click="unbind(scope.row.commerce_sku_id)">解</el-button>
                            <el-button type="primary" @click="bind(scope.row.commerce_sku_id)" size="mini" 
                            title="选择本地商品并完成绑定关系">商绑</el-button>
                            <!-- <el-button type="primary" @click="bindGroup(scope.row.commerce_sku_id)" size="mini" 
                            title="选择本地组合并完成绑定关系">活绑</el-button> -->
                            <el-button type="warning" @click="download_bind(scope.row.commerce_sku_id)" size="mini" 
                            title="下载线上商品并完成绑定关系">增</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称"  min-width="65" align="center">
                        <template slot-scope="scope">
                            {{scope.row.local_goods}}
                        </template>
                    </el-table-column>
                    <el-table-column label="SKU编号"  min-width="65" align="center">
                        <template slot-scope="scope">
                            {{scope.row.local_sku?scope.row.local_sku.join(','):''}}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>

        <!-- 绑商品 -->
        <el-dialog
        :modal-append-to-body="false"
        title="选择SKU"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        :close-on-click-modal="false"
        width="900px"
        center>
            <createShare :online_sku="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>
        <!-- 绑组合 -->
        <el-dialog
        :modal-append-to-body="false"
        title="选择组合"
        :visible.sync="createShareFlag2"
        v-if="createShareFlag2"
        :close-on-click-modal="false"
        width="900px"
        center>
            <selectGroup :online_sku="currentId" @fleshList="getList" @shareExit2="shareExit2"></selectGroup>
        </el-dialog>
        

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>

        <el-dialog
        :modal="false"

        title="导入Excel"
        :visible.sync="showImportFlag"
        :center="true"
        width="600px">
        <div style="max-height:500px;min-height:300px;">
            <el-upload
            style="display:inline-block;margin:0 0 0 210px;text-align:center;"
            :show-file-list="true"
            :action="fileUrl"
            :headers="authorizationBearer"
            :on-success="changeImport">
                <el-button size="small" type="primary" style="width:120px;height:40px;" icon="el-icon-upload2">点击上传</el-button>
            </el-upload>
            <div style="height:40px;line-height:40px;text-align:center;margin-top:30px;">
                <span style="font-size:18px;">Excel格式示例：</span>
            </div>
            <div>
                <img :src="require('../../../assets/img/home/import_sku.png')" style="width:100%;" alt="">
            </div>
        </div>
        </el-dialog>

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="showPicFlag"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="showSrc" alt="">
        </div>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import selectGroup from './selectGroup';
import shareListFlag from './listDialog';
import datas from "./objectData";
import methods from "./method";

export default {
    props:{

    },
    inject: ['selectSide','reload'], 
    data(){
        return datas;
    },
    components:{
        createShare,shareListFlag,selectGroup
    },
    destroyed(){

    },
    methods:methods,
    created(){
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>
<template>
  <div>
       <!-- 创建 -->
        <div style="width:100%;margin:0 auto;background:#fff;text-align:left;">
            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="">
                    <el-input prefix-icon="el-icon-search" v-model="req.keywords" placeholder="请输入关键词搜索"></el-input>
                </el-form-item>
                <el-form-item label="分类">
                    <el-cascader
                    style="display:inline-block;"
                    v-model="req.local_goods_classify"
                    width="130"
                    :options="classify"
                    :show-all-levels="false"
                    :props="{ 
                        expandTrigger: 'hover',
                        children:'child',
                        emitPath:false,
                        value:'id',
                        label:'name'
                    }"></el-cascader>
                </el-form-item>

                <el-form-item label="" style="margin:0 0 0 50px;">
                    <el-button type="primary" @click="req.page=1;getList();">搜索</el-button>
                </el-form-item>
            </el-form>

            <div class="p_container">
                <!-- 右边表格 -->
                <div class="right">
                    <el-table
                        ref="multipleTable"
                        :height="350"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width:100%;text-align: center">
                    <el-table-column align="center" width="65 "> 
                        <template slot-scope="scope"><el-checkbox v-model="scope.row.isCheck" @change="getSelect(scope.row)"></el-checkbox></template>
                    </el-table-column>
                    <el-table-column label="名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info?scope.row.goods_info.name:''}}</template>
                    </el-table-column>
                    <el-table-column label="图片"  min-width="65" align="center">
                        <template slot-scope="scope"><img :src="scope.row.pic" style="width:40px;height:40px;" /></template>
                    </el-table-column>
                    <el-table-column label="规格编码"  min-width="65" align="center">
                        <template slot-scope="scope">{{scope.row.specification_code}}</template>
                    </el-table-column>
                    <el-table-column label="规格属性"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.disposeStr }}</template>
                    </el-table-column>
                    <el-table-column label="单位"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info.unit_info?scope.row.goods_info.unit_info.name:'' }}</template>
                    </el-table-column>
                    </el-table>

                    <div class="_pageDiv" style="">
                        <el-pagination
                            style="margin:10px 0;"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button  @click="exit();">取 消</el-button>
                <el-button type="primary" @click="save();">确 定</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        online_sku:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            dataInit:null,
            defaultProps:{
                children:'child',
                label: 'name'
            },
            
            source: {},
            //
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),
            saveDataInit:null,
            selectData:[],
            tableData: [],
            saveData:{
                name:'',
            },
            total:0,
            classify:[],
            req:{
                limit:30,
                page:1,
                keywords:'',
                local_goods_classify:''
            }
        }
    },
    destroyed(){
    },
    methods:{
        getSelect(item){
            this.tableData.forEach(i=>{
                i.isCheck = false;
            });
            item.isCheck = true;

            this.selectData = [];
            this.selectData.push(item);
        },
        copy(code,item){
            
        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        async save(){
            let flag = false;
            await this.$confirm('确定要绑定这些SKU吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            }).then(() => {
                flag = true;
            }).catch(() => {
                flag = false;
            });

            if(!flag){
                return;
            }

            let sku_list = [];
            this.selectData.forEach(item=>{
                sku_list.push(item.id);
            })
            if(sku_list.length <= 0){
                this.$message({
                    type:'error',
                    message:'最少选择一个sku进行绑定'
                })
                return;
            }
            // 绑定操作
            let loader = tools.loading(false,this);
            await apis.bindGoodsOnlineWithLocal({
                sku_id:this.online_sku,
                local_sku_id:JSON.stringify(sku_list)
            }).then(res=>{
                if(tools.msg(res,this)){
                }
                tools.loading(loader,this);
            }).catch(err=>{
                tools.err(err,this);
                tools.loading(loader,this);
            });

            this.$emit('fleshList');
            this.$emit('shareExit');
        },
        exit(){
            this.$emit('shareExit');
        },
        fleshList(){
            this.$emit('fleshList')
        },

        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            //
            apis.getLocalSkuList(this.req).then((res) => {
                this.tableData = [];
                let obj = res.data.data;
                for(let i in obj){
                    obj[i] && this.tableData.push(obj[i]);
                }

                this.tableData.forEach(item=>{
                    // 
                    item.pic = this.baseUrl+item.pic;
                    // 设置单选
                    this.$set(item,'isCheck',false);

                    // 设置规格属性
                    item.dispose_info = item.dispose_info.reverse();
                    let disposeStr = '';
                    let k='',v='';
                    item.dispose_info.forEach(i=>{
                        let str = i.split(':');
                        k += str[0];
                        v += str[1];
                    });
                    disposeStr = k+' : '+v;
                    this.$set(item,'disposeStr',disposeStr);
                })

                this.total = res.data.total;
            }).catch((err) => {
                tools.err(err,this);
            });
        },
        selectClassfy(obj){
            this.req.classify_id = obj.id;
            this.getList();
        },
        renderContent(h, { node, data, store }) {
            return (
            <span class="custom-tree-node" on-click={ () => this.selectClassfy(data) } style="display:inline-block;min-height:35px;line-height:35px;">
                <span>{node.label}</span>
            </span>);
        },
        resetReq(){
            this.req = tools.copy(this.reqInit);
        }
    },
    
    mounted(){
        // 获取页面数据源
        apis.getBindSource().then(res => {
            this.classify = res.data.local_goods_classify;
        }).catch(err => {
            tools.err(err, this);
        });

        this.getList();    
    }
}
</script>

<style scoped lang="less">
    @import url('../../../assets/less/normal');
</style>